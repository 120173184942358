import * as React from "react"
import Header from "../components/header"
import Layout from "../components/layout"
import Wrapper from "../components/projects/wrapper"
import "../styles/index.scss"

// markup
const IndexPage = () => {
  return (
   <Layout newStyle="dark" pageTitle="home" >
     <Header />
     <Wrapper />
   </Layout>
  )
}

export default IndexPage

