import React from "react";
import { Link } from "gatsby";

function Item({
  title,
  description,
  projectImage,
  backgroundColor,
  titleColor,
  url,
  externalLink
}) {
  return (
    <li className="projects__item" style={{ backgroundColor: backgroundColor }}>
      { externalLink ?
        (
          <a href={url} target="_blank" rel="noopener noreferrer">
          <div className="text">
            <h6 className="projects__item__title" style={{ color: titleColor }}>
              {title}
            </h6>
            <p className="projects__item__description">{description}</p>
          </div>
          <img
            src={projectImage}
            className="project__featured-image"
            alt="Project Feature"
          />
        </a>
        ) : (
          <Link to={url}>
          <div className="text">
            <h6 className="projects__item__title" style={{ color: titleColor }}>
              {title}
            </h6>
            <p className="projects__item__description">{description}</p>
          </div>
          <img
            src={projectImage}
            className="project__featured-image"
            alt="Project Feature"
          />
        </Link>
        )
      }
    </li>
  );
}

export default Item;
