import React from "react";
import Item from "./item";
import { motion } from "framer-motion";
import indicina from "../../images/indicina.png";
import bumpa from "../../images/bumpa.png";
import urbansitter from "../../images/urbansitter.png";
import bmw from "../../images/bmw.png";

const projects = [
  {
    name: "Bumpa",
    description:
      "Helping small businesses manage their businesses effectively.",
    image: `${bumpa}`,
    backgroundColor: "#DAFDEA",
    titleColor: "#009444",
    url: "/projects/bumpa",
  },
  {
    name: "Indicina",
    description:
      "Improving the merchant's experience for managing loan applications.",
    image: `${indicina}`,
    backgroundColor: "#EAEFFF",
    titleColor: "#0235DD",
    url: "/projects/indicina",
  },
  {
    name: "Urbansitter",
    description:
      "Improving the recurring booking process for parents looking for caregivers.",
    image: `${urbansitter}`,
    backgroundColor: "#CCFBFF",
    titleColor: "#00BBCD",
    url: "/projects/urbansitter",
  },
  /*{
    name: "Runbuggy",
    description: "Creating a design system for unified........",
    image: "../../images.png",
    backgroundColor: "#C3EEFF",
    titleColor: "#06B0F2",
    url: "/bumpa"
  }, */
  /* {
    name: "Samson",
    description:
      "Helping businesses create subscription based plans around their services.",
    image: "../../images.png",
    backgroundColor: "#D8DFFE",
    titleColor: "#4361EE",
    url: "/bumpa"
  }, */
];

function Wrapper() {
  const listVariants = {
    hidden: {
      opacity: 0,
      y: "20vh",
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        staggerChildren: 1,
      },
    },
  };

  return (
    <section className="projects">
      <div className="container">
        <div className="projects__header">
          <h2>
            Featured Projects
          </h2>
        </div>
        <div className="projects__content">
          <motion.ul variants={listVariants} initial="hidden" animate="visible"   viewport={{ once: true }}>
            {projects.map((item) => (
              <Item
                title={item.name}
                description={item.description}
                projectImage={item.image}
                backgroundColor={item.backgroundColor}
                titleColor={item.titleColor}
                url={item.url}
                key={item.name}
              />
            ))}
            <Item
              title="BMW Performance Center"
              description="A 3-in-1 Marketing Site built with Gatsby JS and Node JS (development only)"
              projectImage={bmw}
              externalLink="true"
              backgroundColor="#E0EBFF"
              titleColor="#2658BB"
              url="https://bmwperformancecenter.com/"
            />
          </motion.ul>
        </div>
      </div>
    </section>
  );
}

export default Wrapper;
