import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { motion } from "framer-motion";

export default function Header() {
  const hOneVariants = {
    hidden: {
      y: "10vh",
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.7,
        duration: 0.7,
        ease: "easeOut",
      },
    },
  };
  const linkVariants = {
    hidden: {
      y: "10vh",
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.9,
        duration: 0.7,
        ease: "easeOut",
      },
    },
  };


  return (
    <header className="header">
      <div className="container">
        <motion.h1
          variants={hOneVariants}
          initial="hidden"
          animate="visible"
          className="header__title"
        >
          Hello, I am Adebimpe, a Senior Product Designer,focused
          on creating simple, usable and impactful products.
        </motion.h1>
        <motion.div
         variants={linkVariants}
         initial="hidden"
         animate="visible"
        >
          <Link to="/about" className="header__link">
            Learn more about me
          </Link>
        </motion.div>
      </div>
    </header>
  );
}
